import {
  faArrowAltCircleDown,
  faAt,
  faAtom,
  faBell,
  faBooks,
  faCalendarAlt,
  faCamera,
  faChalkboard,
  faChalkboardTeacher,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleChevronLeft,
  faCircleQuestion,
  faComment,
  faCommentsDollar,
  faComputer,
  faCopy,
  faDotCircle,
  faDownload,
  faEnvelopeDot,
  faEnvelopeOpen,
  faExclamationTriangle,
  faExpandAlt,
  faExternalLinkAlt,
  faFileAlt,
  faFlag,
  faFlask,
  faGamepad,
  faGamepadAlt,
  faHandshake,
  faHome,
  faHomeAlt,
  faLayerGroup,
  faMagnifyingGlass,
  faPencilAlt,
  faPenToSquare,
  faPlus,
  faPrint,
  faSquareCheck,
  faSquareExclamation,
  faStar,
  faTrashCan,
  faUserCog,
  faUserCrown,
  faUserGraduate,
  faUsers,
  faUsersClass,
  faUserSecret,
  faUserXmark,
  faVideo,
  faXmark,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';

export const fontAwesomeRegularIcons = {
  faMagnifyingGlass,
  faArrowAltCircleDown,
  faBell,
  faCamera,
  faCheckCircle,
  faChevronLeft,
  faCircle,
  faDotCircle,
  faFlag,
  faHandshake,
  faFlask,
  faUserGraduate,
  faBooks,
  faChalkboardTeacher,
  faUsers,
  faUserCog,
  faPrint,
  faUserCrown,
  faHomeAlt,
  faPencilAlt,
  faUsersClass,
  faGamepadAlt,
  faAt,
  faAtom,
  faHome,
  faExpandAlt,
  faUserSecret,
  faLayerGroup,
  faChalkboard,
  faExclamationTriangle,
  faCalendarAlt,
  faGamepad,
  faXmark,
  faFileAlt,
  faDownload,
  faExternalLinkAlt,
  faSquareExclamation,
  faEnvelopeOpen,
  faEnvelopeDot,
  faCommentsDollar,
  faComputer,
  faCopy,
  faPenToSquare,
  faUserXmark,
  faTrashCan,
  faStar,
  faSquareCheck,
  faComment,
  faCircleQuestion,
  faChevronRight,
  faCircleChevronLeft,
  faPlus,
  faVideo,
  faEnvelope,
};
